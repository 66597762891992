import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Skeleton, Stack } from "@mui/material";
import { NotFoundPage, PageWithHeader } from "@src/appV2/lib";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { type ReactElement } from "react";
import { useParams } from "react-router-dom";

import { useFacilityProfile } from "../Facilities/api/useFacilityProfile";
import { ReviewsSummaryContent } from "./components/ReviewsSummaryContent";
import { OPEN_SHIFTS_ROUTE_V1 } from "./constants";

export function ReviewsSummaryPage(): ReactElement {
  const { workplaceId } = useParams<{
    workplaceId: string;
  }>();
  const { data: facilityProfile, isLoading: isLoadingFacilityProfile } = useFacilityProfile({
    workplaceId,
    projection: "type",
  });

  if (isLoadingFacilityProfile) {
    return (
      <Stack width="100%" spacing={1}>
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Stack>
    );
  }

  if (!isDefined(facilityProfile)) {
    return <NotFoundPage />;
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={facilityProfile.name}
            leftCta={<BackButtonLink alwaysUseDefaultBackTo defaultBackTo={OPEN_SHIFTS_ROUTE_V1} />}
          />
        }
        containerVariant="without-margin"
      >
        <ReviewsSummaryContent
          workPlaceDetails={{
            userId: workplaceId,
            name: facilityProfile.name,
            type: facilityProfile.type,
          }}
        />
      </PageWithHeader>
    </IonPage>
  );
}
