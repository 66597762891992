import { post } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { threadAggregateSchema } from "./useGetThreadAggregates";
import { getWorkplaceCommentsUrl, workplaceCommentSchema } from "./useWorkplaceComments";

const postCommentRequestSchema = z.object({
  text: z.string(),
  anonymous: z.boolean().optional(),
  parentId: z.string().optional(),
  questionId: z.string().optional(),
});

const postCommentResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    relationships: z.object({
      parentComment: z.object({ data: workplaceCommentSchema }).optional(),
      question: z.object({ data: threadAggregateSchema }).optional(),
    }),
  }),
});

export type PostCommentRequest = z.infer<typeof postCommentRequestSchema>;
export type PostCommentResponse = z.infer<typeof postCommentResponseSchema>;

export function usePostComment(
  workplaceId: string,
  options: UseMutationOptions<PostCommentResponse, AxiosError, PostCommentRequest> = {}
): UseMutationResult<PostCommentResponse, AxiosError, PostCommentRequest> {
  return useMutation({
    mutationFn: async (data: PostCommentRequest) => {
      const response = await post({
        url: getWorkplaceCommentsUrl(workplaceId),
        data,
        requestSchema: postCommentRequestSchema,
        responseSchema: postCommentResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_COMMENT_FAILURE,
    },
    ...options,
  });
}
