import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const getWorkplaceWorkerShiftsRequestSchema = z.object({
  workerId: z.string(),
  workplaceId: z.string(),
});

const getWorkplaceWorkerShiftsResponseSchema = z.array(z.object({ _id: z.string() }));

export type GetWorkplaceWorkerShiftsRequest = z.infer<typeof getWorkplaceWorkerShiftsRequestSchema>;
export type GetWorkplaceWorkerShiftsResponse = z.infer<
  typeof getWorkplaceWorkerShiftsResponseSchema
>;

const GET_WORKPLACE_WORKERS_SHIFTS_PATH = "/workplace/workers/shifts";

export function useGetWorkplaceWorkerShifts(
  queryParams: GetWorkplaceWorkerShiftsRequest,
  options: UseGetQueryOptions<GetWorkplaceWorkerShiftsResponse> = {}
): UseQueryResult<GetWorkplaceWorkerShiftsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKPLACE_WORKERS_SHIFTS_PATH}`,
    queryParams: { ...queryParams, pageSize: 1, projections: ["_id"] },
    requestSchema: getWorkplaceWorkerShiftsRequestSchema,
    responseSchema: getWorkplaceWorkerShiftsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_WORKERS_SHIFTS_FAILURE,
    },
    ...options,
  });
}
