import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { aggregateAttributesSchema } from "./useGetMultipleChoiceAggregates";

export const threadAggregateSchema = z.object({
  id: z.string(),
  attributes: aggregateAttributesSchema,
});

export type ThreadAggregate = z.infer<typeof threadAggregateSchema>;

const getThreadAggregatesResponseSchema = z.object({
  data: z.array(threadAggregateSchema),
});

export type GetThreadAggregatesResponse = z.infer<typeof getThreadAggregatesResponseSchema>;

export function getThreadAggregatesUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/thread-comments-aggregates`;
}

export function useGetThreadAggregates(
  workPlaceId: string,
  options: UseQueryOptions<ThreadAggregate[], AxiosError> = {}
): UseQueryResult<ThreadAggregate[]> {
  return useQuery({
    queryKey: [getThreadAggregatesUrl(workPlaceId)],
    queryFn: async () => {
      const response = await get({
        url: getThreadAggregatesUrl(workPlaceId),
        responseSchema: getThreadAggregatesResponseSchema,
      });
      return response.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_THREAD_AGGREGATES_FAILURE,
    },
    ...options,
  });
}
