import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { QuestionTag } from "../types";

export const questionTagSchema = z.object({
  tag: z.nativeEnum(QuestionTag),
  id: z.string(),
});

export const aggregateAttributesSchema = z.object({
  question: z.object({ tags: z.array(questionTagSchema) }),
  questionId: z.string(),
  repliesCount: z.number(),
  text: z.string(),
});

export type AggregateAttributes = z.infer<typeof aggregateAttributesSchema>;

export type QuestionTagType = z.infer<typeof questionTagSchema>;

const multipleChoiceAggregateSchema = z.object({
  id: z.string(),
  attributes: aggregateAttributesSchema,
});

export type MultipleChoiceAggregate = z.infer<typeof multipleChoiceAggregateSchema>;

const getMultipleChoiceAggregatesResponseSchema = z.object({
  data: z.array(multipleChoiceAggregateSchema),
});

export type GetMultipleChoiceAggregatesResponse = z.infer<
  typeof getMultipleChoiceAggregatesResponseSchema
>;

function getMultipleChoiceAggregatesUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/multiple-choice-comments-aggregates`;
}

export function useGetMultipleChoiceAggregates(
  workPlaceId: string,
  options: UseQueryOptions<MultipleChoiceAggregate[], AxiosError> = {}
): UseQueryResult<MultipleChoiceAggregate[]> {
  return useQuery({
    queryKey: [getMultipleChoiceAggregatesUrl(workPlaceId)],
    queryFn: async () => {
      const response = await get({
        url: getMultipleChoiceAggregatesUrl(workPlaceId),
        responseSchema: getMultipleChoiceAggregatesResponseSchema,
      });
      return response.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_MULTIPLE_CHOICE_AGGREGATES_FAILURE,
    },
    ...options,
  });
}
