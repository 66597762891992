import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  type InfiniteData,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { getPageCursor } from "../utils";
import {
  getWorkplaceCommentsUrl,
  type WorkplaceCommentsParams,
  workplaceCommentsRequestSchema,
  type WorkplaceCommentsResponse,
  workplaceCommentsResponseSchema,
  type WorkplaceCommentType,
} from "./useWorkplaceComments";

export type PaginatedWorkplaceCommentsParams = Omit<WorkplaceCommentsParams, "page">;

export function getPaginatedWorkplaceCommentsQueryKey(params: PaginatedWorkplaceCommentsParams) {
  const { workplaceId, filter } = params;
  return ["paginated-comments", getWorkplaceCommentsUrl(workplaceId), filter];
}

export function mergePaginatedWorkplaceComments(
  updatedComment: WorkplaceCommentType,
  previousData?: InfiniteData<WorkplaceCommentsResponse>
) {
  if (!isDefined(previousData)) {
    return previousData;
  }

  const newPages = previousData.pages.map((commentPage) => {
    const newData = commentPage.data.map((comment) =>
      comment.id === updatedComment.id ? updatedComment : comment
    );
    return {
      ...commentPage,
      data: newData,
    };
  });

  return {
    ...previousData,
    pages: newPages,
  };
}

export function usePaginatedWorkplaceComments(
  params: PaginatedWorkplaceCommentsParams,
  options: UseInfiniteQueryOptions<WorkplaceCommentsResponse, AxiosError> = {}
): UseInfiniteQueryResult<WorkplaceCommentsResponse> {
  const { workplaceId, filter } = params;
  return useInfiniteQuery({
    queryKey: getPaginatedWorkplaceCommentsQueryKey(params),
    queryFn: async ({
      pageParam: pageParameter = undefined,
    }: QueryFunctionContext<QueryKey, WorkplaceCommentsResponse["links"]["next"]>) => {
      const response = await get({
        url: getWorkplaceCommentsUrl(workplaceId),
        queryParams: {
          filter,
          page: {
            cursor: pageParameter,
          },
        },
        responseSchema: workplaceCommentsResponseSchema,
        requestSchema: workplaceCommentsRequestSchema,
      });
      return response.data;
    },
    getNextPageParam: (lastPage) =>
      isDefined(lastPage.links.next) ? getPageCursor(lastPage.links.next) : undefined,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REVIEW_COMMENTS_FAILURE,
    },
    ...options,
  });
}
