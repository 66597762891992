import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const postRatingRequestSchema = z.object({
  rating: z.number(),
});

const postRatingResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.string(),
    attributes: z.object({ rating: z.number() }),
  }),
});

export type PostRatingRequest = z.infer<typeof postRatingRequestSchema>;
export type PostRatingResponse = z.infer<typeof postRatingResponseSchema>;

function getRatingsPostUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/ratings`;
}

export function usePostRating(
  workplaceId: string,
  options: UseMutationOptions<PostRatingResponse, AxiosError, PostRatingRequest> = {}
): UseMutationResult<PostRatingResponse, AxiosError, PostRatingRequest> {
  return useMutation({
    mutationFn: async (data: PostRatingRequest) => {
      const response = await post({
        url: getRatingsPostUrl(workplaceId),
        data,
        requestSchema: postRatingRequestSchema,
        responseSchema: postRatingResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_RATING_FAILURE,
    },
    ...options,
  });
}
