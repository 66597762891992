import { type ApiResponse, post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const postReviewRequestSchema = z.object({
  anonymous: z.boolean(),
  reviewDate: z.string().datetime().optional(),
  answers: z.array(
    z.object({
      questionId: z.string(),
      text: z.string().optional(),
      optionId: z.string().optional(),
    })
  ),
});

const postReviewResponseSchema = z.object({
  data: z.object({
    type: z.string(),
    attributes: z.object({ count: z.number() }),
  }),
});

export type PostReviewRequest = z.infer<typeof postReviewRequestSchema>;
export type PostReviewResponse = z.infer<typeof postReviewResponseSchema>;

export function usePostReview(
  workplaceId: string,
  options: UseMutationOptions<ApiResponse<PostReviewResponse>, AxiosError, PostReviewRequest> = {}
): UseMutationResult<ApiResponse<PostReviewResponse>, AxiosError, PostReviewRequest> {
  return useMutation({
    mutationFn: async (data: PostReviewRequest) => {
      return await post({
        url: `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/reviews`,
        data,
        requestSchema: postReviewRequestSchema,
        responseSchema: postReviewResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_REVIEW_FAILURE,
    },
    ...options,
  });
}
