import { remove } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import {
  getCommentsReactionUrl,
  type GetCommentsReactionUrlParams,
} from "./usePostCommentReaction";
import { workplaceCommentSchema } from "./useWorkplaceComments";

const deleteCommentReactionResponseSchema = z.object({
  data: workplaceCommentSchema,
});

export type DeleteCommentReactionResponse = z.infer<typeof deleteCommentReactionResponseSchema>;

export function useDeleteCommentReaction(
  params: GetCommentsReactionUrlParams,
  options: UseMutationOptions<DeleteCommentReactionResponse, AxiosError> = {}
): UseMutationResult<DeleteCommentReactionResponse, AxiosError, void> {
  return useMutation({
    mutationFn: async () => {
      const response = await remove({
        url: getCommentsReactionUrl(params),
        responseSchema: deleteCommentReactionResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.DELETE_COMMENT_REACTION,
    },
    ...options,
  });
}
