import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { Reaction } from "../types";
import { workplaceCommentSchema } from "./useWorkplaceComments";

const postCommentReactionRequestSchema = z.object({
  reaction: z.nativeEnum(Reaction),
});

const postCommentReactionResponseSchema = z.object({
  data: workplaceCommentSchema,
});

export type PostCommentReactionRequest = z.infer<typeof postCommentReactionRequestSchema>;
export type PostCommentReactionResponse = z.infer<typeof postCommentReactionResponseSchema>;

export interface GetCommentsReactionUrlParams {
  workplaceId: string;
  commentId: string;
}

export function getCommentsReactionUrl(params: GetCommentsReactionUrlParams): string {
  const { workplaceId, commentId } = params;
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/comments/${commentId}/reactions`;
}

export function usePostCommentReaction(
  params: GetCommentsReactionUrlParams,
  options: UseMutationOptions<
    PostCommentReactionResponse,
    AxiosError,
    PostCommentReactionRequest
  > = {}
): UseMutationResult<PostCommentReactionResponse, AxiosError, PostCommentReactionRequest> {
  return useMutation({
    mutationFn: async (data: PostCommentReactionRequest) => {
      const response = await post({
        url: getCommentsReactionUrl(params),
        data,
        requestSchema: postCommentReactionRequestSchema,
        responseSchema: postCommentReactionResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_COMMENT_REACTION,
    },
    ...options,
  });
}
