import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Box, Divider, Paper, Skeleton, Stack } from "@mui/material";
import { useFacilityProfile } from "@src/appV2/Facilities/api/useFacilityProfile";
import { NotFoundPage, PageWithHeader } from "@src/appV2/lib";
import { AppBarHeader, BackButtonLink } from "@src/appV2/lib/AppBarHeader";
import { type InfiniteData, useQueryClient } from "@tanstack/react-query";
import { type ReactElement } from "react";
import { generatePath, Redirect, useParams } from "react-router-dom";

import { type GetCommentResponse, useGetComment } from "./api/useGetComment";
import {
  getPaginatedWorkplaceCommentsQueryKey,
  mergePaginatedWorkplaceComments,
  type PaginatedWorkplaceCommentsParams,
  usePaginatedWorkplaceComments,
} from "./api/usePaginatedWorkplaceComments";
import { getCommentUrl } from "./api/usePatchComment";
import { type WorkplaceCommentsResponse } from "./api/useWorkplaceComments";
import { ReviewCommentForm } from "./components/Comments";
import { CommentCard } from "./components/Comments/CommentCard";
import { RepliesList } from "./components/Replies/RepliesList";
import { useResetAndRefetchPaginatedComments } from "./hooks/useResetAndRefetchPaginatedComments";
import { WorkplaceReviewsRouterPath } from "./paths";

export function CommentRepliesPage(): ReactElement {
  const { workplaceId, commentId } = useParams<{
    workplaceId: string;
    commentId: string;
  }>();
  const queryClient = useQueryClient();

  const { data: facilityProfile, isLoading: isLoadingFacilityProfile } = useFacilityProfile({
    workplaceId,
    projection: "type",
  });

  const {
    data: comment,
    isLoading: isCommentLoading,
    isError: isGetCommentError,
  } = useGetComment({
    workplaceId,
    commentId,
  });

  const workplaceCommentsParams: PaginatedWorkplaceCommentsParams = {
    workplaceId,
    filter: { parentCommentId: commentId },
  };

  const {
    data: repliesData,
    isLoading,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchWorkplaceComments,
  } = usePaginatedWorkplaceComments(workplaceCommentsParams, {
    enabled: isDefined(facilityProfile) && isDefined(comment),
    meta: {
      userErrorMessage: "Something went wrong while loading replies",
    },
  });

  const { resetAndRefetchPages } = useResetAndRefetchPaginatedComments({
    refetchComments: refetchWorkplaceComments,
    workplaceCommentsParams,
  });

  if (isGetCommentError) {
    return (
      <Redirect
        to={generatePath(WorkplaceReviewsRouterPath.REVIEWS_SUMMARY_PATH, {
          workplaceId,
        })}
      />
    );
  }

  if (isLoadingFacilityProfile || isCommentLoading) {
    return (
      <Stack width="100%" spacing={1}>
        <Skeleton variant="rectangular" width="100%" height={100} />
        <Skeleton variant="rectangular" width="100%" height={300} />
        <Skeleton variant="rectangular" width="100%" height={300} />
      </Stack>
    );
  }

  if (!isDefined(facilityProfile) || !isDefined(comment)) {
    return <NotFoundPage />;
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={facilityProfile.name}
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={generatePath(WorkplaceReviewsRouterPath.REVIEWS_SUMMARY_PATH, {
                  workplaceId,
                })}
              />
            }
          />
        }
        footer={
          <>
            <Divider />
            <ReviewCommentForm
              workplaceId={workplaceId}
              parentId={commentId}
              inputPlaceholder="Add Reply..."
              anonymousCheckboxLabel="I want my reply to be anonymous"
              onSubmit={async () => {
                await resetAndRefetchPages();
              }}
            />
          </>
        }
        containerVariant="without-margin"
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: (theme) => theme.zIndex.modal,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              paddingTop: 1,
              paddingRight: 1,
            }}
          >
            <CommentCard
              comment={comment.data}
              workplaceId={workplaceId}
              onUpdate={(updatedComment) => {
                queryClient.setQueryData<GetCommentResponse>(
                  [getCommentUrl({ workplaceId, commentId }), undefined],
                  () => {
                    return { data: updatedComment };
                  }
                );
              }}
            />
          </Paper>
          <Divider />
        </Box>
        <RepliesList
          workplaceId={workplaceId}
          replyPages={repliesData?.pages ?? []}
          isLoading={isLoading || isRefetching}
          isFetchingNextPage={isFetchingNextPage}
          onUpdate={(updatedReply) => {
            queryClient.setQueryData<InfiniteData<WorkplaceCommentsResponse>>(
              getPaginatedWorkplaceCommentsQueryKey(workplaceCommentsParams),
              (previousData) => mergePaginatedWorkplaceComments(updatedReply, previousData)
            );
          }}
          onLoadNextPage={hasNextPage ? fetchNextPage : undefined}
        />
      </PageWithHeader>
    </IonPage>
  );
}
