import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { getCommentUrl } from "./usePatchComment";
import { workplaceCommentSchema } from "./useWorkplaceComments";

interface GetCommentParams {
  workplaceId: string;
  commentId: string;
}

const getCommentResponseSchema = z.object({
  data: workplaceCommentSchema,
});

export type GetCommentResponse = z.infer<typeof getCommentResponseSchema>;

export function useGetComment(
  params: GetCommentParams,
  options: UseGetQueryOptions<GetCommentResponse> = {}
): UseQueryResult<GetCommentResponse> {
  const { commentId, workplaceId } = params;
  return useGetQuery({
    url: getCommentUrl({ commentId, workplaceId }),
    responseSchema: getCommentResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REVIEW_COMMENT_FAILURE,
      userErrorMessage: "Something went wrong while fetching comment",
    },
    ...options,
  });
}
