import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { ReviewQuestionType } from "../types";

const getQuestionsRequestSchema = z.object({
  workplaceType: z.string(),
  workplaceId: z.string(),
});

const reviewOptionSchema = z.object({
  displayText: z.string(),
  id: z.string(),
});

export type ReviewOption = z.infer<typeof reviewOptionSchema>;

const reviewQuestionSchema = z.object({
  id: z.string(),
  attributes: z.object({
    question: z.string(),
    type: z.nativeEnum(ReviewQuestionType),
    options: z.array(reviewOptionSchema),
  }),
});

export type ReviewQuestion = z.infer<typeof reviewQuestionSchema>;

const getQuestionsResponseSchema = z.object({
  data: z.array(reviewQuestionSchema),
});

export type GetQuestionsRequest = z.infer<typeof getQuestionsRequestSchema>;
export type GetQuestionsResponse = z.infer<typeof getQuestionsResponseSchema>;

const GET_QUESTIONS_PATH = "/workplaces/questions";

export function useGetQuestions(
  queryParams: GetQuestionsRequest,
  options: UseGetQueryOptions<GetQuestionsResponse> = {}
): UseQueryResult<GetQuestionsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}${GET_QUESTIONS_PATH}`,
    queryParams,
    requestSchema: getQuestionsRequestSchema,
    responseSchema: getQuestionsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REVIEW_QUESTIONNAIRE_FAILURE,
    },
    ...options,
  });
}
