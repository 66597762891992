import { patch } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { workplaceCommentSchema } from "./useWorkplaceComments";

const patchCommentRequestSchema = z.object({
  anonymous: z.boolean(),
});

const patchCommentResponseSchema = z.object({
  data: workplaceCommentSchema,
});

export type PatchCommentRequest = z.infer<typeof patchCommentRequestSchema>;
export type PatchCommentResponse = z.infer<typeof patchCommentResponseSchema>;

export interface PatchCommentParams {
  workplaceId: string;
  commentId: string;
}

export function getCommentUrl(params: PatchCommentParams): string {
  const { workplaceId, commentId } = params;
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/comments/${commentId}`;
}

export function usePatchComment(
  params: PatchCommentParams,
  options: UseMutationOptions<PatchCommentResponse, AxiosError, PatchCommentRequest> = {}
): UseMutationResult<PatchCommentResponse, AxiosError, PatchCommentRequest> {
  return useMutation({
    mutationFn: async (data: PatchCommentRequest) => {
      const response = await patch({
        url: getCommentUrl(params),
        data,
        requestSchema: patchCommentRequestSchema,
        responseSchema: patchCommentResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PATCH_COMMENT_FAILURE,
    },
    ...options,
  });
}
